<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Hey again, adventurers!
      <br><br>
      Last week we released an article listing
      <router-link to="/community/news-network/s/334961">a few tips on earning coins in LEGO Universe</router-link>.
      Here are a few more tricks that are sure to fill your minifigure’s piggy bank before you can say
      “I’m going to buy everything in Nexus Tower twice!” As in the last article, our averages were
      taken using minifigures with Rank 3 gear and no Valiant Weapons. Your results may be different!
      <br>
    </p>
    <div class="center">
      <!-- pic49B21FB5-5739-4577-B8E5-DD92EF566EA3.jpg -->
      <img src="@/assets/news-network/how-to-earn-coins.jpg" class="rounded">
    </div>
    <br>
    1.  <b>Racing</b>
    <br><br>
    •  Racing is a gamble. If you win, you can take home a decent amount of reward packages filled
    with coins, cars, and Faction Tokens. There are plenty of reasons to practice your racing skills,
    though: not only are its Achievement rewards fun and unique, but we also offer plenty of official
    racing contests, so those who hone their skills could be in line for tons of LEGO sets, high-quality
    sellable gems, and unique in-game reward items.
    <br>
    •  Average coins per hour – 3,000 to 7,000 (depending on placing)
    <br><br>
    2.  <b>Grinding</b>
    <br><br>
    •  Grinding (defeating a mass of enemies repeatedly to stockpile the valuable loot they drop) is
    the second best way to earn a fortune of coins, items, and Faction Tokens.
    <br><br>
    •  We were surprised to learn that you can actually earn more coins grinding in Avant Gardens than
    in Forbidden Valley or Gnarled Forest by using a simple tactic: smashing and rebuilding two
    Stromling Mechs into turrets, then watching as wave after wave of Stomlings get smashed in their
    wake. It’s a regular coin harvest.
    <br>
    •  Average coins per hour – 12,500
    <br><br>
    •  Grinding more difficult enemies, like Apes or Dragons, offers both a higher payoff and a higher
    risk. The increased chance for rares like parrots, briefcases, or the King’s Crown (all of which
    can be sold for many a coin) makes it worthwhile – as long as you work with a team.
    <br>
    •  Average coins per hour (solo, Forbidden Valley Maelstrom Horsemen) – 9,000
    <br>
    •  Average coins per hour (group, Forbidden Valley Maelstrom Horsemen) – 15,500
    <br><br>
    •  Players with low-level gear should grind safe mobs as much as possible until they have their
    Level 3 Faction Gear set, at which point it will be much safer to attempt grinding tougher mobs.
    <br><br>
    3.  <b>Achievement Hunting</b>
    <br><br>
    •  If you’re not sure on where or how to earn your cash, let your Passport guide you! Find areas
    where you haven’t unlocked grinding Achievements and grind there. Same goes for minigames,
    Missions – everything. The Achievement awards you’ll receive will earn you a nice amount of extra
    coins. It definitely adds up.<br>o  Average coins per hour (hunting Achievements in Gnarled Forest)
    – 4,500
    <br><br>
    4.  <b>Begging</b>
    <br><br>
    •  There may be a lot of nice minifigures out there, but you shouldn’t take advantage of them
    by begging for coins! Earn your money by putting in hard work and thinking outside the box and
    your shiny new Valiant Weapon will mean ten times more once you buy it.
    <br>
    •  Average coins per hour – 42
    <br><br>
    With these economic tips up your sleeves you’re sure to be a richer minifigure in no time.
    Want to share a coin-earning trick of your own? Do so on the
    <router-link to="/messageboards/3981675">message board</router-link>!
    <p></p>
  </div>
</template>

<script>
export default {};
</script>
